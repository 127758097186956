<template>
  <div class="col-md-2 responsive-painel-it col-sm-6" style="margin-bottom: 20px;" v-if="color">
      <div class="col-md-12" :style="'background-color: #'+color+';border-radius: 3px;'">
          <div style="min-height: 35px;">
              <div class="painel-titulo">{{ name }}</div>
              <div style="text-align: center;padding-bottom: 5px;background-color: #9dadbf57;">
                  <router-link :to="link" class="btn btn-lg painel-link">
                      <i class="entypo entypo-eye va-icon entypo entypo-eye painel-icon" aria-hidden="true"></i>
                      Visualizar
                  </router-link>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "box-link",
  props: {
    color: '',
    name:  '',
    link:  ''
  },
  data() {
    return {};
  },
  methods: {

  },
});
</script>


